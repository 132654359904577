
<template>
  <div class="page-contener">
       <Nav></Nav>
        <HeaderStyle :title="title" :describe="describe" :backgroundUrl="backgroundUrl"></HeaderStyle>
         <div class="server-desc">
            <Title :content="content"></Title>
            <div class="content-box">
              <img src="@/assets/social/soc-bg1.png" alt="">
              <p class="content-box-p"> 为有效防范解决预付式消费跑路风险及矛盾纠纷问题，优化预付式消费市场营商环境，维护社会和谐稳定。项目运用区块链、云计算和大数据等技术，围绕政策制定者、行业监管者、市场消费者、商业经营者、社会金融保险服务者提供服务能力。作为公司辅助政府进行社会治理的典型项目，预付式消费风险防范解决方案获得中共中央立法机构、中央政法委、市监总局、商务部及各地区政府单位的高度重视。其中，北京市石景山区落地案例作为先行先试的创新试点，作为中央政法委2021年十大创新案例向郭声琨书记汇报，<span>习总书记列席会议，并在全国进行经验推广。</span></p>
            </div>
         </div>
         <div class="box-wrap">
           <Title :content="content1"></Title>
           <div class="img-box">
              <img src="@/assets/social/ditu.png" alt="" class="ditu-size">
           </div>
           <div class="text-box">
            <div v-for="(item,index) in cases" :key="index" :class="item.style">
              <div class="case-item-w">
                <div class="case-item">
                  <img :src="item.icon" alt="">
                  <span>{{item.desc}}</span>
                </div>
                <p>{{item.text}}</p>
              </div>
            </div>
           </div>
         </div>
         <div class="play-box">
           <!-- 轮播 -->
           <ArrowCur :news="news" v-if="flag"></ArrowCur>
         </div>
    </div>
</template>

<script>
import api from "@/api/index";
import { baseURL} from '@/config'
import HeaderStyle from '@/components/commom/HeaderStyle'
import Nav from "@/components/layer/HeaderNav";
import Title from "@/components/layer/OneTitle.vue";
import ArrowCur from "@/components/commom/ArrowCur";
export default {
  name: 'SocialGovernance',
  components: {
    HeaderStyle,
    Nav,
    Title,
    ArrowCur
  },
  data() {
    return {
        flag:false,
        news:[],
      	pageNum: 1, // 当前页码
				pageSize: 100, // 总页数 1 4
        title:"社会治理",
        backgroundUrl:"backgroundUrl",
        describe:"党的的十八届三中全会提出了社会治理这个概念，要从过去政府一元化社会管理体制，逐步转变为政府与各类社会主体多元化协同社会治理的体制，强调社会力量在公共事务治理中的作用。十九届四中全会又进一步提出要“完善党委领导、政府负责、民主协商、社会协同、公众参与、法治保障、科技支撑的社会治理体系”。",
        content: {
            name: "预付式消费监管服务概述",
            egName: "ABOUT PREPAID CONSUMER "
        },
        content1:{
          name: "落地案例",
          egName: "GOVERNANCE EFFECT"
        },
        cases:[{
          icon:require("@/assets/social/icon1.png"),
          desc:"已上线",
          style:"l-box",
          text:"由公司承建的预付式消费监管和服务平台已在北京市石景山区、通州区、大兴区、顺义区、东城区、海淀区、丰台区、门头沟区；山东德州市、济南高新区、烟台市；河南郑州中原区、郑州高新区、开封市；江苏南京江北新区、苏州市、无锡市、南通市；四川成都郫都区等32个地区上线运营。"
        },
        // {
        //   icon:require("@/assets/social/icon2.png"),
        //   desc:"确定合作",
        //   style:"r-box",
        //   text:"天津、石家庄、杭州、武汉、宜昌、贵阳、兰州、广州、深圳等多个城市的预付费项目已在陆续确定合作意向。 "
        // }
      
      
      ]
    }
  },
  created() {

  },
  computed: {
  },
  mounted() {
    	this.getList();
  },
  methods: {
     // 动态新闻 社会治理
   	getList() {
      let categoryId = 23 //  社会治理
			api.news.getList({
				articleId: null,
				categoryId: categoryId,
				pageNum: this.pageNum,
				pageSize: this.pageSize, // 1 4
			}).then((res) => {
        if(res.code == 0){
          this.flag = true
          let pageInfo = res.data.pageInfo
          this.total = pageInfo.total          
          if(pageInfo && pageInfo.list && pageInfo.list.length){
             pageInfo.list.forEach(item => {
                item.url = baseURL +  item.coverImage
                item.text = item.title
             });
             this.news = pageInfo.list

             console.log("this.news123",this.news)
          }
        }
      });
		},
  }
}
</script>

<style lang="scss" scope>
.page-contener{
  font-family: PingFangSC-Regular;
  .server-desc{
    .content-box{
      display: flex;
      justify-content: center;
      padding-bottom:0.65rem;
      img{
        width:5.68rem;
        margin-right:0.90rem;
      }
      .content-box-p{
        width: 7.16rem;
        padding-top:0.47rem;
        font-size: 0.18rem;
        color: #1C1C1C;
        letter-spacing: 0;
        line-height: 0.36rem;
        text-indent: 0.38rem;
        span{
          color:#2E70FF
        }
      }
    }
  }
  .play-box{
    width:100%;
    height:3.72rem;
    position: relative;
    /deep/.governService{
      position: relative;
      top:0.30rem;
    }
  }
}
  .box-wrap{
    width:100%;
    height:11.09rem;
    background-image: url("../../assets/social/soc-bg2.png");
    .title-c .content .name{
      color:#fff;
    }
    .title-c .content .eg-name{
       color:#fff;
    }
    .img-box{   
      text-align: center;
      .ditu-size{
      width: 8.31rem;
      height:5.59rem;
      margin: 0 auto;
      }
    }
    .text-box{
      display: flex;
      justify-content: center;
      .l-box{
        margin-right:0.73rem;
       }
    .l-box,.r-box{
      width:5.99rem;
    }
    }
  }
.backgroundUrl{
    background-image:url("../../assets/social/s-banner.png");
    background-size:100% 100%;
}
.case-item-w{
  color:#fff;
  .case-item{
    display: flex;
    align-items: center;
    padding-bottom:0.24rem;
    span{
      font-size:0.20rem;
    };
  img{
    width:0.24rem;
    height: 0.24rem;
    padding-right: 0.12rem;
  }
  }
    p{
    font-size: 0.18rem;
    line-height: 0.38rem;
  }
}
</style>
